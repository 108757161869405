body {
    padding: 0px !important;
}
.readmore-main-container {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 96px;
    padding-bottom: 46px;
    display: flex;
}
.heading-one {
    color: #183b56;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Satoshi Variable, sans-serif;
    font-size: 47px;
    font-weight: 700;
}
.readmores-container {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    flex-direction: column;
    display: flex;
}
.readmore-question-answer-container {
    background-color: #fff;
    border: 1px solid #cbd5e1;
    border-radius: 16px;
    overflow: hidden;
}
.question-icon {
    align-items: center;
    padding: 24px;
    display: flex;
}
.readmore-heading {
    color: #183b56;
    letter-spacing: .2px;
    /* cursor: pointer; */
    flex: 1;
    margin-top: 0;
    margin-bottom: 0;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
}
.ans-container {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    border-top: 1px solid #94a3b8;
    flex-direction: column;
    margin-left: 24px;
    margin-right: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    overflow: hidden;
}
.readmore-answer {
    color: #475569;
    margin-bottom: 0;
    margin-top: 0;
    font-family: "Inter";
    font-size: 16px;
    line-height: 24px;
}
.main-section {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 46px;
    padding-bottom: 96px;
}
.block-div-1 {
    margin-bottom: 32px;
}
.heading-one.trending {
    font-size: 32px;
    line-height: 48px;
}
.heading-one {
    color: #334155;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Satoshi Variable, sans-serif;
    font-weight: 700;
}
.trending-blocks {
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    margin-top: 24px;
    display: flex;
}
.city-block {
    color: #c3196b;
    justify-content: center;
    align-items: center;
    height: 32px;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: none;
    display: flex;
}
.city-box {
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    flex-wrap: wrap;
    margin-top: 24px;
    display: flex;
}
.footer-main {
    background-color: #1e212d;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

}
.footer-container {
    z-index: 0;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: relative;
}
.footer-container-row-1 {
    justify-content: space-between;
    display: flex;
}
.footer-container-information-1 {
    flex-direction: column;
    align-items: flex-start;
    max-width: 384px;
    display: flex;
}
.footer-container-logo {
    margin-bottom: 24px;
}
.inline-div {
    max-width: 100%;
    display: inline-block;
}
.footer-container-col-1.contact-us.email {
    margin-left: 0;
    display: block;
}
.heading-two {
    color: #5a7184;
    margin-top: 0;
    margin-bottom: 0;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-decoration: none;
}
.block-div-2 {
    margin-top: 24px;
    margin-bottom: 0;
}
.wa-btn {
    align-items: flex-start;
    text-decoration: none;
    display: flex;
}
.wa-icon {
    opacity: 1;
    margin-top: 5px;
    margin-right: 12px;
    transition: opacity .2s;
}
.wa-link {
    opacity: .7;
    color: #959ead;
    font-family: "Inter";
    font-size: 16px;
    line-height: 24px;
    transition: opacity .2s;
}
.em-btn {
    align-items: flex-start;
    margin-top: 8px;
    text-decoration: none;
    display: flex;
}
.em-icon {
    opacity: 1;
    margin-right: 12px;
    transition: opacity .2s;
}
.footer-container-row-2 {
    display: flex;
    flex-direction: row;
}
.block-div-3 {
    margin-right: 180px;
}
.heading-two.navigate-links {
    color: #5a7184;
    text-align: left;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-decoration: none;
}
.heading-two {
    color: #5a7184;
    margin-top: 0;
    margin-bottom: 0;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-decoration: none;
}
.block-div-4 {
    justify-content: space-between;
    align-items: stretch;
    display: flex;
}
.footer-container-col-1 {
    color: #e9eef1;
    margin-left: 0;
}
.link-div-1.links {
    margin-bottom: 0;
    margin-right: 30px;
    padding-left: 0;
}
.link-div-1 {
    flex-direction: column;
    margin-top: 24px;
    font-weight: 500;
    display: flex;
}
.no-style-list {
    padding-left: 0;
    list-style: none;
}
.menu-div-item-1 {
    flex-direction: row;
    align-items: flex-start;
    margin-top: 6px;
    margin-bottom: 6px;
    display: flex;
}
.link-item-footer {
    opacity: .7;
    color: #959ead;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    transition: opacity .2s;
}
.social-div {
    display: flex;
    align-items: center;
}
._two {
    height: 82px;
    position: relative;
    left: 48px;
}
.block-div-6 {
    grid-column-gap: 16px;
    grid-row-gap: 13px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    margin-top: 24px;
    display: grid;
}
.block-div-7 {
    align-items: flex-start;
    margin-top: 48px;
    display: flex;
}
.location-div {
    width: 34px;
    height: 40px;
    background-color: #64748B;
    clip-path: path("M5 9.26562C5 5.39563 8.13 2.26562 12 2.26562C15.87 2.26562 19 5.39563 19 9.26562C19 13.4356 14.58 19.1856 12.77 21.3756C12.37 21.8556 11.64 21.8556 11.24 21.3756C9.42 19.1856 5 13.4356 5 9.26562ZM9.5 9.26562C9.5 10.6456 10.62 11.7656 12 11.7656C13.38 11.7656 14.5 10.6456 14.5 9.26562C14.5 7.88562 13.38 6.76562 12 6.76562C10.62 6.76562 9.5 7.88562 9.5 9.26562Z");
}
.location-text {
    opacity: .7;
    color: #959ead;
    width: 300px;
    height: 90px;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
.block-div-8 {
    background-color: #1e293b;
    border-radius: 2px;
    height: 2px;
    margin-top: 32px;
    margin-bottom: 32px;
    margin-right: 50px;
}
.block-div-9 {
    display: flex;
    justify-content: space-between;
}
.social-icons {
    margin-right: 16px;
}
.block-div-10 {
    margin-left: 230px;
    margin-right: 24px;
    display: flex;
    position: static;
    left: 115px;
}
.block-div-11 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-items: start;
    display: grid;
}
.footer-navigate-links-1 {
    opacity: .5;
    color: #5a7184;
    text-align: right;
    width: auto;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    transition: opacity .2s;
}
.link-items.tbtn {
    font-weight: 500;
}
.link-items {
    color: #5a7184;
    font-family: "Inter";
}
.cookie-item-link {
    opacity: .5;
    color: #5a7184;
    text-align: right;
    width: auto;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    transition: opacity .2s;
}
.terms-item-link {
    opacity: .5;
    color: #5a7184;
    text-align: right;
    width: auto;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    transition: opacity .2s;
}
.grievance-item-link {
    opacity: .5;
    color: #5a7184;
    text-align: right;
    width: auto;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    transition: opacity .2s;
}
.grievance-item-link-2 {
    opacity: .5;
    color: #5a7184;
    text-align: left;
    width: auto;
    margin-right: 20px;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    transition: opacity .2s;
}
.privacy-item-link {
    opacity: .5;
    color: #5a7184;
    text-align: right;
    width: auto;
    margin-right: 20px;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    transition: opacity .2s;
}
.grievance-item-link-1 {
    opacity: .5;
    color: #5a7184;
    text-align: right;
    width: auto;
    margin-right: 20px;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    transition: opacity .2s;
}
/* .ct-main {
    padding: 0 23px;
} */
.heading-two.cts.new {
    color: #5a7184;
    font-size: 18px;
}
.heading-two.cts {
    font-family: "Inter";
    font-weight: 600;
    line-height: 24px;
    text-decoration: none;
}
.block-div-12 {
    grid-column-gap: 28px;
    grid-row-gap: 28px;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 16px;
    display: flex;
}
.block-div-13 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
}
.footer-navigate-link-2.heading-size {
    color: rgba(92, 106, 122, .9);
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}
.block-div-14 {
    grid-column-gap: 14px;
    grid-row-gap: 14px;
    flex-direction: row;
    display: flex;
}
.full-stack-dev-c {
    grid-column-gap: 14px;
    grid-row-gap: 14px;
    flex-flow: wrap;
    align-items: flex-start;
    display: flex;
}
.footer-navigate-link-2.para-size.tbtn {
    flex: none;
}
.footer-navigate-link-2.para-size {
    color: rgba(51, 75, 94, .99);
    font-size: 16px;
}
.footer-navigate-link-2 {
    font-family: "Inter";
    font-weight: 500;
}
.tb-1 {
    color: #334b5d;
    font-size: 16px;
    margin-top: -4px;
}
.block-div-15 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-direction: column;
    display: flex;
}
.data-analytics-c {
    grid-column-gap: 14px;
    grid-row-gap: 14px;
    flex-flow: wrap;
    align-items: flex-start;
    width: 1140px;
    display: flex;
}
.block-div-16 {
    grid-column-gap: 14px;
    grid-row-gap: 14px;
    flex-flow: wrap;
    align-items: flex-start;
    display: flex;
}
a {
    color: inherit;
    text-decoration: inherit;
}
.tbtn * {
    pointer-events: none;
}
.readmore{
    background-color: #f9f0f5;
}
* {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
    /* background-color: #f9f0f5; */
}
.footer-navigate-link-2.para-size:hover {
    color: white;
}
.footer-navigate-link-2.heading-size :hover {
    color: white;
}
.link-items:hover {
    color: white
}
.link-item-footer:hover {
    color: white;
}
.list-item {
    grid-column-gap: 7px;
    grid-row-gap: 7px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 5px;
    margin-bottom: 0;
    padding-left: 28px;
    list-style-type: decimal;
    display: flex;
}



@media(max-width:576px) {
    .readmore-main-container {
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        width: 95%;
    }
    .heading-one {
        text-align: left;
        font-size: 24px;
        line-height: 34px;
    }
    .ans-container {
        margin-left: 16px;
        margin-right: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
        display: flex;
    }
    .readmore-answer {
        color: #5a7184;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 24px;
    }
    .list-item {
        color: #5a7184;
        padding-left: 20px;
        font-weight: 400;
        line-height: 26px;
    }
    .main-section {
        padding: 48px 16px;
        width: 90%;
    }
    .heading-one,
    .heading-one.trending {
        font-size: 24px;
        line-height: 34px;
    }
    .trending-blocks {
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        flex-direction: column;
        align-items: flex-start;
        display: flex;
    }
    .city-block {
        height: 30px;
        font-size: 16px;
        line-height: 24px;
    }
    .city-box {
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        flex-flow: wrap;
        align-items: start;
        justify-items: start;
        display: flex;
    }
    .footer-main {
        padding-bottom: 32px;
        display: block;
        position: static;
    }
    .footer-container {
        z-index: 0;
        width: 85%;
    }
    .footer-container-row-1 {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0;
        padding: 0;
    }
    .footer-container-information-1 {
        align-items: flex-start;
        max-width: 340vw;
        margin-bottom: 36px;
    }
    .footer-container-logo {
        margin-bottom: 24px;
    }
    .footer-container-col-1.contact-us.email {
        width: 100%;
        margin-top: 0;
    }
    .footer-container-row-2 {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }
    .block-div-3 {
        margin-bottom: 24px;
        margin-left: 0;
        margin-right: 0;
        display: block;
    }
    .block-div-4 {
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        margin-left: 0;
    }
    .link-div-1.links {
        margin-bottom: 0;
        margin-right: 0;
    }
    .menu-div-item-1 {
        text-align: center;
        margin-top: 0;
        margin-bottom: 16px;
    }
    .link-item-footer {
        text-align: left;
        font-size: 14px;
        text-decoration: none;
    }
    .block-div-17 {
        align-items: center;
        margin-left: 90px;
    }
    .link-div-1.links._two {
        color: #959ead;
        flex-direction: column;
        width: 140px;
        height: auto;
        font-family: "Inter";
        font-weight: 400;
        line-height: 24px;
        position: static;
    }
    .block-div-6 {
        grid-row-gap: 16px;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .block-div-7 {
        margin-top: 24px;
    }
    .location-text {
        width: 260px;
        height: 96px;
        margin-left: 2px;
    }
    .block-div-8 {
        margin-top: 24px;
        margin-bottom: 24px;
        max-width: 500px;
        margin-right: 40px;
    }
    .block-div-9 {
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
    }
    .block-div-10 {
        text-align: center;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        margin-top: 24px;
        margin-left: 0;
        margin-right: 0;
        position: static;
    }
    .block-div-11 {
        grid-template-rows: auto auto auto;
        grid-template-columns: 1fr 1fr;
    }
    .ct-main {
        padding: 0;
    }
    .block-div-12 {
        grid-column-gap: 24px;
        grid-row-gap: 24px;
    }
    .block-div-13 {
        grid-column-gap: 22px;
        grid-row-gap: 22px;
    }
    .footer-navigate-link-2.heading-size {
        font-size: 15px;
    }
    .block-div-14 {
        grid-column-gap: 18px;
        grid-row-gap: 18px;
        flex-wrap: wrap;
    }
    .full-stack-dev-c {
        display: flex;
        flex-wrap: wrap;
        width: 85%;
        max-width: 500px;

    }
    .block-div-15 {
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        flex-wrap: wrap;

    }
    .data-analytics-c {
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        flex-wrap: wrap;
        width: 85%;
        max-width: 500px;
    }
    .block-div-16 {
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        flex-wrap: wrap;
        width: 300px;
    }
}


@media (min-width:577px) and (max-width:768px) {
    .readmore-main-container {
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        width: 95%;

    }
    .heading-one {
        text-align: left;
        font-size: 28px;
        line-height: 34px;
    }
    .main-section {
        /* padding: 48px 16px; */
        width: 93%;
    }
    .trending-blocks {
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        flex-direction: column;
        align-items: flex-start;
        display: flex;
    }
    .footer-navigate-link-2.heading-size {
        font-size: 16px;
    }
    .ct-main {
        padding: 0;
    }
    .block-div-14 {
        grid-column-gap: 18px;
        grid-row-gap: 18px;
        flex-wrap: wrap;
        width: 94%;
        max-width: 768px;
    }
    .block-div-15 {
        grid-column-gap: 18px;
        grid-row-gap: 18px;
        flex-wrap: wrap;
        width: 94%;
        max-width: 768px;
    }
    .data-analytics-c {
        grid-column-gap: 18px;
        grid-row-gap: 18px;
        flex-wrap: wrap;
        width: 98%;
        max-width: 768px;
    }
    .footer-main {
        padding-bottom: 32px;
        display: block;
        position: static;
    }
    .footer-container {
        z-index: 0;
        padding-left: 24px;
        padding-right: 12px;
    }
    .footer-container-row-1 {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0;
        padding: 0;
    }
    .footer-container-information-1 {
        align-items: flex-start;
        max-width: 340vw;
        margin-bottom: 36px;
    }
    .footer-container-logo {
        margin-bottom: 24px;
    }
    .footer-container-col-1.contact-us.email {
        width: 100%;
        margin-top: 0;
    }
    .footer-container-row-2 {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }
    .block-div-3 {
        margin-bottom: 24px;
        margin-left: 0;
        margin-right: 0;
        display: block;
        width: 350px;
    }
    .block-div-4 {
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        margin-left: 0;
    }
    .block-div-9 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
    }
    .block-div-10 {
        text-align: center;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        margin-top: 24px;
        margin-left: 0;
        margin-right: 0;
        position: static;
    }
}



@media (min-width:769px) and (max-width:1024px) {
    .readmore-main-container {
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        width: 95%;

    }
    .main-section {
        /* padding: 48px 16px; */
        width: 93%;
    }
    .block-div-3 {
        margin-right: 50px;
    }
    .block-div-9 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
    }
    .block-div-10 {
        text-align: center;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        margin-top: 24px;
        margin-left: 0;
        margin-right: 0;
        position: static;
    }
    .footer-container {
        padding: 0 25px;
    }
    .footer-container-row-1 {
        flex-direction: column;

    }
    .footer-container-information-1 {
        padding-bottom: 20px;
    }
    .ct-main {
        padding: 0;
    }
    .block-div-14 {
        grid-column-gap: 18px;
        grid-row-gap: 18px;
        flex-wrap: wrap;
        width: 95%;
        max-width: 1000px;
    }
    .block-div-15 {
        grid-column-gap: 18px;
        grid-row-gap: 18px;
        flex-wrap: wrap;
        width: 94%;
        max-width: 1000px;
    }
    .data-analytics-c {
        grid-column-gap: 18px;
        grid-row-gap: 18px;
        flex-wrap: wrap;
        width: 98%;
        max-width: 1000px;
    }
}




@media (min-width:1025px) and (max-width:1200px) {
    .readmore-main-container {
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        width: 95%;
    }
    .main-section {
        /* padding: 48px 16px; */
        width: 93%;
    }
    .block-div-14 {
        grid-column-gap: 18px;
        grid-row-gap: 18px;
        flex-wrap: wrap;
        width: 95%;
        max-width: 1200px;
    }
    .block-div-15 {
        grid-column-gap: 18px;
        grid-row-gap: 18px;
        flex-wrap: wrap;
        width: 94%;
        max-width: 1200px;
    }
    .data-analytics-c {
        grid-column-gap: 18px;
        grid-row-gap: 18px;
        flex-wrap: wrap;
        width: 98%;
        max-width: 1200px;
    }
    .footer-container {
        padding: 0 25px;
    }
}