.h2a-4 {
  color: transparent;
  background: linear-gradient(90deg, #401760, #a35d94);
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Satoshi Variable, ;
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
}
.why-int-comaprison-table {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin-top: 48px;
  display: flex;
  position: relative;
}

.comparison-row-div {
  z-index: 1;
  display: block;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.comparison-row-heading-div {
  height: 77px;
}

.comparison-rows-container {
  border: 1px solid #f9f0f5;
  border-radius: 23px 24px 24px 23px;
  overflow: hidden;
}

.comparison-odd-rows.odd {
  background-color: #f9f0f5;
}

.comparison-odd-rows {
  color: #334155;
  background-color: #fff;
  align-items: center;
  height: 106px;
  padding-left: 48px;
  font-family: "Inter" ;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  display: flex;
  overflow: hidden;
}

.comparison-even-rows.even {
  background-color: #fff;
}

.comparison-even-rows {
  color: #334155;
  background-color: #f9f0f5;
  align-items: center;
  height: 106px;
  padding-left: 48px;
  font-family: "Inter" ;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  display: flex;
}

.comparison-column-div {
  z-index: 3;
  grid-column-gap: 34px;
  grid-row-gap: 34px;
  flex-direction: row;
  justify-content: flex-end;
  width: 70%;
  display: flex;
  position: relative;
}

.comparison-int-col-container-2 {
  background-color: rgba(255, 255, 255, .8);
  border: 2px solid #461a62;
  border-radius: 24px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.comparison-col1-heading-2 {
  color: #fff;
  text-align: center;
  background-color: #461a62;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 77px;
  padding-top: 24px;
  padding-bottom: 24px;
  font-family: "Inter" ;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  display: flex;
}

.comparison-content-div-4 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 106px;
  padding: 36px;
  display: flex;
}
.done-tick{
 background-color: #d3eddc;
  border-radius: 50px;
  padding: 2px;
  font-size: 35px;
  text-align: center;
  align-items: center;
  color: #4fa64c;
}
.cross-tick{
  background-color: #f3ccce;
  border-radius: 50px;
  padding: 2px;
  font-size: 35px;
  text-align: center;
  align-items: center;
  color:#dc2c2b ;
}
.comparison-txt {
  color: #475569;
  text-align: center;
  font-family: "Inter" ;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.comparison-image-div {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.awards-section-imgs {
  height: 32px;
  width: 100px;
}
.comparison-other-col-container-2 {
  background-color: rgba(255, 255, 255, .8);
  border: 1px solid #d5dde7;
  border-radius: 22px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.comparison-col2-heading {
  color: #475569;
  text-align: center;
  background-color: #f9f0f5;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 77px;
  padding-top: 24px;
  padding-bottom: 24px;
  font-family: "Inter" ;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  display: flex;
}

.comparison-txt1 {
  color: #475569;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 106px;
  padding: 36px;
  font-family: "Inter" ;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  display: flex;
}

.why-join-intensive-container-div {
  align-items: flex-start;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 96px;
  padding-bottom: 96px;
  display: block;
}

.date-changes-cms-collection-container {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
  display: flex;
}

.left-aligin {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.intensive-batch-starts {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
}

.start-batch-circle {
  background-color: #c76a97;
  border: 7.2px solid #dbeafe;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.int-batch-txt-copy {
  color: #475569;
  font-family: "Inter" ;
  font-size: 19px;
  font-weight: 500;
  line-height: 24px;
}

.dates-changing-ctas {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.book-a-free-demo-main-btn {
  color: #fff;
  text-align: center;
  background-color: #461a62;
  border-radius: 12px;
  width: 196px;
  border:none;
  margin-right: 0;
  padding: 12px 32px;
  font-family: "Inter" ;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  display: block;
}

/* fullStack Developer */
.main-container-div {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 46px;
  padding-bottom: 46px;
}

.h2a {
  color: #334155;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Satoshi Variable, ;
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
}

.pricing-sub-heading-div {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: #475569;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-top: 32px;
  margin-bottom: 48px;
  font-family: "Inter" ;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  display: flex;
}

.pricing-tabs {
  width: 100%;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.faq-w-tabs {
  position: relative;
  display: flex;
  gap: 20px;
}

.pricing-tab-menu {
  color: #64748b;
  background-color: #f9f0f5;
  border-radius: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  padding: 2px 4px;
  margin-top: 20px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.pricing-postpaid-tab-item.w--current {
  color: #334155;
  background-color: #fff;
  border: 1px solid #f9f0f5;
  border-radius: 8px;
  font-family: "Inter" ;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, .06);
}

.w-tab-link {
  vertical-align: top;
  text-align: center;
  cursor: pointer;
  color: #222;
  font-size: 18px;
  padding: 10px 16px;
  width: 290px;
  text-decoration: none;
  display: inline-block;
  position: relative;
  border: none;
}
.post-paid-icons{
   width: 30px !important;
    height: 30px !important;
     color: #c76a97
}
.pricing-prepaid-tab-item {
  color: #64748b;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 18px;
  font-family: "Inter" ;
  line-height: 24px;
  display: flex;
}

.tabs-content-29 {
  overflow: visible;
}

.w-tab-content {
  display: block;
  position: relative;
  overflow: hidden;
}

.w--tab-active {
  display: block;
}

/* .w-tab-pane {
    position: relative;
  } */

.pricing-postpaid-tab-content {
  border: 1px solid #f9f0f5;
  border-radius: 16px;
  flex-wrap: wrap;
  margin-top: 32px;
  padding: 48px;
}

.pricing-post-paid-title {
  font-family: "Inter" ;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.whats-included-content-container {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-flow: wrap;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: flex-start;
  margin-bottom: 24px;
  display: grid;
}

.whats-included-features-div {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  color: #475569;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
  font-family: "Inter" ;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.whats-included-features-list-div {
  margin-bottom: 0;
}

.features-list {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: #475569;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 64px;
  font-size: 15px;
  font-family: "Inter";
  list-style-type: disc;
  display: flex;
}

.whats-included-txt1 {
  color: #475569;
  width: 90%;
  font-family: "Inter" ;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.features-list-div {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  align-items: center;
  width: 100%;
  display: flex;
}

.pricing-logos-post-paid {
  height: 48px;
  width: 48px;
  min-width: 48px;
  max-width: 48px;
}

.pricing-logos-container {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
}

.tooltip-dropdown {
  z-index: 0;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.w-dropdown {
  text-align: left;
  z-index: 900;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  position: relative;
}

.w-dropdown-toggle {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  padding-right: 40px;
  display: inline-block;
}


.w-dropdown-btn,
.w-dropdown-toggle,
.w-dropdown-link {
  vertical-align: top;
  color: #222;
  text-align: left;
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-decoration: none;
  position: relative;
}

.tooltip-text {
  color: #fff;
  flex-direction: row;
  align-items: center;
  display: flex;
  position: relative;
  cursor: pointer;
}

.track-button * {
  pointer-events: none;
}

.pricing-logos-post-paid {
  height: 48px;
  width: 48px;
  min-width: 48px;
  max-width: 48px;
}

.text-block-941248789-copy.light-red._475569 {
  color: #475569;
}

.text-block-941248789-copy.light-red {
  color: #dc2626;
  margin-left: 6px;
  
}

.text-block-941248789-copy {
  color: #475569;
  width: 90%;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.image-812882270 {
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 28px;
  bottom: 3px;
  right: -20px;
  color: black;
}


.tooltip-anchor {
  display: none;
}

.tooltip-anchor {
  z-index: 100;
  background-color: transparent;
  justify-content: center;
  width: 100%;
  height: 10px;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  padding-top: 12px;
  display: flex;
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 0%;
  right: 0%;
}

.tooltip-wrapper {
  background-color: #fff;
  border: 1px solid #d8e3f0;
  border-radius: 10px;
  width: 390px;
  display: block;
  position: absolute;
  box-shadow: 1px 1px 8px 1px rgba(45, 62, 80, .12);
}

.tooltip-title {
  color: #334155;
  margin: 15px 1px;
  font-family: "Inter" ;
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;
}



.tooltip-diamond.bottom {
  top: auto;

}

.tooltip-diamond {
  z-index: 2;
  background-color: #fafbfe;
  border: 1px solid #d8e3f0;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: -7px;
  bottom: auto;
  left: 0%;
  right: 0%;
  transform: rotate(45deg);
  box-shadow: 0 -3px 8px rgba(45, 62, 80, .06);
}

.text-block-941248790-copy {
  color: #1e3a8a;
  margin-left: 24px;
  margin-right: 24px;
  font-family: DM Sans, ;
  font-size: 48px;
  font-weight: 700;
  line-height: 34px;
}

.program-pricing-term {
  color: #475569;
  font-family: "Inter" ;
  font-weight: 500;
}

.program-original-price {
  color: #1e3a8a;
  font-family: "Inter" ;
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
}



.discount-price-5 {
  align-items: center;
  margin-bottom: 4px;
  display: flex;
  position: relative;
}

.div-block-1831980521407 {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.div-block-1831980521441 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-block-1831980521391-copy {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border-top: 1px solid #f9f0f5;
  border-left: 0 solid #f9f0f5;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 48px;
  padding: 56px 0 0;
  display: flex;
}

.div-block-1831980521406 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 32px;
  display: flex;
}

.program-price-term1 {
  color: #1e3a8a;
  font-family: "Inter" ;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.program-pricing-term {
  color: #475569;
  font-family: "Inter" ;
  font-weight: 500;
}

.new-scholar-ship-pricing-container-jfs {
  border: 1px solid #f9f0f5;
  border-radius: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 430px;
  height: 144px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  position: relative;
}

.ribbion-container-2 {
  position: absolute;
  top: 16px;
  left: -13px;
}

.apply-scholorship-text-2 {
  color: #fff;
  font-family: "Inter" ;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  position: absolute;
  top: 4px;
  left: 28px;
}

.ribbon-glow {
  animation: flow 1.5s linear infinite;
}

.ribbon-glow {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: center;
  padding-left: 10px;
  display: flex;
  position: absolute;
}

.ribbion-glow-2 {
  background-color: rgba(255, 255, 255, .2);
  width: 12px;
  height: 32px;
  position: static;
  left: 28px;
  transform: skew(-29deg);
}

.ribbion-glow-1 {
  background-color: rgba(255, 255, 255, .2);
  width: 12px;
  height: 32px;
  position: static;
  left: 9px;
  transform: skew(-29deg);
}

.image-812882290 {
  width: 293px;
  height: 43px;
  left: -27px;
}

.scholarship-pricing-container {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 40px;
  display: flex;
}

.pricing-text-2 {
  color: #ea580c;
  font-family: "Inter", ;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.book-a-free-demo-container {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.track-class * {
  pointer-events: none;
}

.request-acall-back-link {
  color: #461a62;
  text-align: center;
  font-family: "Inter" ;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.money-image-styles {
  height: 109px;
  width: 114px;
}

.fee-increase-strip-2 {
  color: #334155;
  background-color: #fef3c7;
  border-radius: 12px;
  width: 345px;
  height: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  font-family: "Inter", ;
  font-weight: 600;
  line-height: 24px;
  display: none;
  position: relative;
  overflow: hidden;
}

.fee-increase-image {
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.fee-increase-image2 {
  width: 26px;
  height: 26px;
  position: absolute;
  top: auto;
  bottom: 14%;
  left: auto;
  right: 3%;
}

/* .scholarship-pricing-mobile {
    display: none;
  } */
.new-scholar-ship-pricing-container-jfs {
  border: 1px solid #f9f0f5;
  border-radius: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 430px;
  height: 144px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  position: relative;
}

.ribbion-container-2 {
  position: absolute;
  top: 16px;
  left: -13px;
}

.apply-scholorship-text-2 {
  color: #fff;
  font-family: "Inter", ;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  position: absolute;
  top: 4px;
  left: 28px;
}

demo-main-btn {
  color: #fff;
  text-align: center;
  background-color: #461a62;
  border-radius: 12px;
  width: 196px;
  margin-right: 0;
  padding: 12px 32px;
  font-family: "Inter" ;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.book-a-free-demo-main-btn {
  color: #fff;
  text-align: center;
  background-color: #461a62;
  border-radius: 12px;
  width: 196px;
  margin-right: 0;
  padding: 12px 32px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.reserve-seat-postpaid-button {
  color: #461a62;
  background-color: #fff;
  border: 1px solid #461a62;
  border-radius: 8px;
  padding: 12px 22px;
  font-family: "Inter" ;
  font-weight: 600;
  text-decoration: none;
}

/* Full Stack Developer Course in Hyderabad */

.main-container-div {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 96px;
  padding-bottom: 96px;
}

.h2a {
  color: #334155;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Satoshi Variable, ;
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
}

.job-opp-info-copy {
  color: #475569;
  margin-top: 24px;
  font-family: "Inter";
  font-size: 19px;
  font-weight: 500;
  line-height: 24px;
}

.link-block-blue-color {
  color: #c3196b;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.swiper-nav-btns {
  margin-top: 20px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-nav-swiper-button,
.right-nav-swiper-button {
  background: none;
  border: none;
  color: #64748B;
  padding: 10px;
  cursor: pointer;
}

.custom-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
}

.progressbar-nav-item {

  display: flex;
  align-items: center;
  width: 20px;
  height: 5px;
  border-radius: 30%;
  background-color: #e2e8f0;
  margin: 0 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.progressbar-nav-item.active {
  background-color: #C76A97;
}

/* .card-1-founder carousels */

.awarded-slider {
  position: relative;
}

.awarded-slider-main-container {
  position: relative;
}

.arrows-container-single-imgs-carousel {
  position: absolute;
  bottom: 10px;
  /* Adjust this value based on your design */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  /* Adjust padding as needed */
  box-sizing: border-box;
}

.arrow {
  cursor: pointer;
}

.arrow-left {
  position: absolute;
  left: 41%;
  top: 12px;
}

.arrow-right {
  position: absolute;
  right: 41%;
  top: 10px;
}
.why-join-intensive, .pricing-2{
  background-color: #f9f0f5;
}

.arrow-icon {
  font-size: 28px;
  /* Adjust size as needed */
  color: #000;
  /* Adjust color as needed */
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 8px !important;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: #461a62;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card-1-founder {
  gap: 46px;
  grid-row-gap: 46px;
  background-color: #1e3a8a;
  border: 1px solid #f9f0f5;
  border-radius: 24px;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  height: 320px;
  padding-left: 48px;
  padding-right: 48px;
  display: flex;
}

.text-block-9412882473 {
  color: #fff;
  width: 704px;
  font-family: "Inter" ;
  font-size: 24px;
  font-weight: 400;
  line-height: 42px;
}

.span-gold-color-bold {
  color: #f8d246;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(84deg, #e3b21f, #f8d246 20% 44%, #fad84c 74%, #daa20a);
  -webkit-background-clip: text;
  background-clip: text;
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
}

.div-block-1831980521447257 {
  aspect-ratio: 2.39;
  flex: none;
  width: 364px;
  height: 240px;
}

.each-card-div {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 48px;
  height: 225px;
}

.award-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
}

.w-slider {
  text-align: center;
  clear: both;
  -webkit-tap-highlight-color: #0000;
  tap-highlight-color: #0000;
  background: #ddd;
  height: 300px;
  position: relative;
}

.slider-awarded {
  background-color: #0000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  display: flex;
  gap: 10px;
}

.paragraph-695 {
  color: #fff;
  font-family: "Inter" ;
  font-size: 24px;
  font-weight: 400;
  line-height: 42px;
}

.div-block-1831980521447257-copy {
  aspect-ratio: 2.39;
  flex: none;
  width: 364px;
  height: 240px;
}

.card-1.recogined-card {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-flow: column;
  padding-top: 32px;
  padding-bottom: 32px;
}

.recognised-text {
  color: #fff;
  margin-bottom: 24px;
  font-family: "Inter", ;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.card-bg-container {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  background-color: #fff;
  border-radius: 24px;
  flex-direction: column;
  align-items: center;
  height: 176px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
  display: flex;
}

.nasscom {
  width: 180px;
  height: 40px;
}

.nsdc-india {
  width: 180px;
  height: 40px;
}

.startup-india {
  width: 180px;
  height: 40px;
}

.ministry-india {
  width: 100%;
  height: 40px;
}

.div-block-1831980521447258 {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  align-items: center;
  display: flex;
}

.card-1-founder.recogined-card {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-flow: column;
  padding-top: 32px;
  padding-bottom: 32px;
}

.awarded-slider {
  background-color: #f9f0f5;
  padding-top: 96px;
  padding-bottom: 96px;
  display: block;
  overflow: hidden;
}

.date-changes-container {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.fouder-btn {
  margin: 80px 1px 30px 1px;
}

/* Full Stack Developer Course Fee */
.pricing-prepaid-tab-item.w--current {
  color: #334155;
  background-color: #fff;
  border: 1px solid #f9f0f5;
  border-radius: 8px;
  box-shadow: 1px 1px 3px #0000000f;
}

.w-tab-link:focus {
  outline: 0;
}

.tooltip-content {
  visibility: hidden;
  width: 380px;
  color: #000;
  text-align: left;
  position: absolute;
  bottom: 100%;
  left: 70%;
  transform: translateX(-50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 999;
  background-color: #f9f0f5;
  border-radius: 12px;
  padding: 20px 24px;
}


.tooltip-text:hover .tooltip-content {
  visibility: visible;
}

.tooltip-title.last {
  margin-bottom: 0;
  margin-top: 20px;
  font-size: 17px;
}

.pricing-prepaid-tab-content {
  grid-column-gap: 96px;
  grid-row-gap: 96px;
  border: 1px solid #f9f0f5;
  border-radius: 16px;
  justify-content: space-around;
  align-items: center;
  margin-top: 32px;
  padding: 32px;
  display: flex;
}

.pricing-pre-paid-title {
  color: #334155;
  font-family: "Inter", ;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.prepaid-features-container {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  display: flex;
}

.prepaid-txt1 {
  color: #461a62;
  font-family: "Inter", ;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.prepaid-txt2 {
  color: #461a62;
  font-family: DM Sans, ;
  font-size: 48px;
  font-weight: 700;
  line-height: 34px;
}

.prepaid-txt3 {
  color: #475569;
  font-family: 'Inter', ;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.prepaid-pricing-details-div {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 64px 32px 96px;
  display: flex;
}

.discounted-price {
  color: #64748b;
  align-items: center;
  margin-bottom: 4px;
  font-family: "Inter", ;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  display: flex;
  position: relative;
}

.discounted-program-price {
  color: #64748b;
  font-family: "Inter", ;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

.program-price {
  color: #461a62;
  font-family: "Inter", ;
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
}

.strike-3-copy {
  width: 120px;
  margin-left: -112px;
}

.strike-3 {
  width: 120px;
  margin-left: -112px;
}



/* Full Stack Developer Course Learner Reviews */
.learner-expriences-videos-section {
  background-color: #f9fbfe;
}

.learner-mask-reviews {
  margin: 10px;
  cursor: pointer;
}

.card-image-reviews {
  width: 100%;
  height: auto;
  display: block;
}

.card-container-reviews {
  max-width: 345px;
}

.next-arrow-reviews {
  position: absolute;
  right: -1168px;
  top: -200px;
  z-index: 1;
}

.prev-arrow-reviews {
  position: absolute;
  left: -50px;
  top: 140px !important;
  z-index: 1;
}

.arrow-icon {
  font-size: 28px;
  font-weight: 500;
}

.learner-review-main-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 96px;
  padding-bottom: 96px;
}


.learner-heading {
  color: #183b56;
  margin-top: 0;
  margin-bottom: 24px;
  padding-bottom: 0;
  font-family: sofia-pro, ;
  font-size: 48px;
  font-weight: 600;
  line-height: 64px;
}

.text-block-941288239896 {
  margin-bottom: 24px;
  font-family: "Inter", ;
  font-size: 18px;
  line-height: 24px;
}

.w-slider-mask {
  z-index: 1;
  white-space: nowrap;
  height: 100%;
  display: block;
  position: relative;
  left: 0;
  right: 0;
  overflow: hidden;
}

.text-block-9412882469 {
  color: #183b56;
  font-family: "Inter", ;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  white-space: normal;
  word-wrap: break-word;
}

/* Frequently Asked Questions */
.faq-section {
  background-color: #f9f0f5;
}

.faq-main-container-y {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 96px;
  padding-bottom: 96px;
  font-family: "Inter", ;
}

.faq-main-heading-y {
  color: #183b56;
  letter-spacing: -.5px;
  margin-top: 0;
  margin-bottom: 48px;
  font-family: Satoshi Variable, ;
  font-size: 48px;
  font-weight: 600;
  line-height: 64px;
}

.faqs-all-tabs-y {
  color: #5a7184;
  flex-direction: column;
  flex: none;
  width: 20%;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.w-tab-menu {
  position: relative;
}

.faq-tab-link-y {
  color: #64748b;
  background-color: #0000;
  border-left: 2px solid #e2e8f0;
  padding-left: 15px;
  font-family: "Inter", ;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.w-tab-link {
  vertical-align: top;
  text-align: left;
  cursor: pointer;
  color: #222;
  /* background-color: #ddd; */
  padding: 9px 30px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.faqs-tab-content-y {
  width: 100%;
}

.w-tab-content {
  display: block;
  position: relative;
  overflow: hidden;
}

.question-answers-container-y {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.faq-qaa-main-conatainer-y {
  background-color: #fff;
  border: 1px solid #f9f0f5;
  border-radius: 16px;
  overflow: hidden;
}

.faq-q-icon-container-y.track-button {
  display: flex;
}

.faq-q-icon-container-y {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  display: flex;
}

.faq-q-h-y {
  color: #183b56;
  letter-spacing: 0.2px;
  cursor: pointer;
  flex: 1;
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Inter", ;
  font-size: 18px;
  line-height: 27px;
  text-shadow: 0.1px 0 0 #183b56, -0.1px 0 0 #183b56;
}

.icon-14 {
  flex: none;
  width: 24px;
  height: 24px;
}

.faq-dropdown-arrow {
  clip-path: path("M9.74998 6.71063C9.32748 7.10063 9.32748 7.73063 9.74998 8.12063L13.9533 12.0006L9.74998 15.8806C9.32748 16.2706 9.32748 16.9006 9.74998 17.2906C10.1725 17.6806 10.855 17.6806 11.2775 17.2906L16.25 12.7006C16.6725 12.3106 16.6725 11.6806 16.25 11.2906L11.2775 6.70063C10.8658 6.32063 10.1725 6.32063 9.74998 6.71063Z");
  background-color: #475569;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-top: 0;
  transform: rotate(90deg);
}

.faq-ans-y {
  display: none;
}

.faq-anws-container-y {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  margin: 0 30px 16px 24px;
  display: block;
  overflow: hidden;
}

.faq-ans-p-y {
  color: #5a7184;
  margin-bottom: 0;
  font-family: "Inter", ;
  font-size: 17px;
  line-height: 36px;
}

a {
  color: #c3196b ;
  text-decoration: none;
}

.faq-tab-link-y.w--current {
  border-left-color: #c76a97;
  color: #c76a97;
}

.faq-ans-p-y p {
  margin: 0;
  padding: 0;
  line-height: 1.8;
}

.faq-ans-p-y h6 {
  font-size: 10;
  font-weight: bold;
  line-height: 26px;

}

/* mobile screens responsive  */
@media (min-width: 320px) and (max-width: 767px) {

  /* The TechU Advantage */
  .why-join-intensive-container-div {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .h2a-4,
  .h2a-4.trending-heading {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
  }

  .comparison-odd-rows {
    height: 128px;
    padding-left: 16px;
    font-size: 14px;
    line-height: 20px;
    width: 125px;
  }

  .comparison-even-rows {
    background-color: #f9f0f5;
    height: 128px;
    padding-left: 16px;
    font-size: 14px;
    line-height: 20px;
    width: 125px;
  }

  .comparison-txt2 {
    width: 100px;
  }

  .comparison-column-div {
    grid-column-gap: 9px;
    grid-row-gap: 9px;
    justify-content: flex-end;
    width: 70%;
    display: flex;
  }

  .comparison-col1-heading-2 {
    padding: 16px 13px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    overflow: hidden;
  }

  .comparison-txt1 {
    height: 128px;
    padding: 24px 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }

  .comparison-content-div-4 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    background-color: #fcfdfe;
    height: 128px;
    padding: 16px 8px;
    font-size: 14px;
  }

  .comparison-image-div {
    flex-direction: column;
  }

  .comparison-col2-heading {
    height: 77px;
    padding: 16px 13px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
  }

  .date-changes-cms-collection-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 32px;
    display: flex;
  }

  .book-a-free-demo-main-btn {
    width: 338px;
    margin-right: -18px;
    padding: 13px 38px;
    font-size: 20px;
  }

  /* Full Stack Developer Course Fee */
  .main-container-div {
    padding: 48px 16px;
  }

  .h2a,
  .h2a.trending-heading {
    font-size: 24px;
    line-height: 34px;
  }

  .pricing-sub-heading-div {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 34px;
    font-size: 16px;
    line-height: 24px;
    display: flex;
  }

  .pricing-tab-menu {
    background-color: #f9f0f5;
    border: 1px solid #f9f0f5;
    border-radius: 8px;
    width: 100%;
    padding: 2px;
    display: flex;
  }

  .pricing-postpaid-tab-content {
    background-color: #fff;
    border: 1px solid #f9f0f5;
    border-radius: 16px;
    flex-direction: column;
    width: auto;
    margin-top: 24px;
    padding: 24px 16px;
    font-family: "Inter", ;
    font-weight: 500;
    display: flex;
  }

  .whats-included-content-container {
    display: block;
  }

  .div-block-1831980521391-copy {
    display: block;
  }

  .div-block-1831980521441 {
    flex-direction: column;
    width: 100%;
  }

  .new-scholar-ship-pricing-container-jfs {
    background-color: #fff;
    border: 1px solid #f9f0f5;
    border-radius: 16px;
    justify-content: space-between;
    width: 100%;
    height: 140px;
    margin-top: 20px;
    padding: 6px 4px 6px 8px;
    display: flex;
  }

  .div-block-1831980521391-copy {
    padding: 25px 0 0;
  }

  .program-original-price {
    font-size: 30px;
  }

  .text-block-941248790-copy {
    font-size: 34px;
    line-height: 27px;
  }

  .div-block-1831980521406 {
    padding: 15px 1px 15px 1px;
  }

  .program-price-term1 {
    font-size: 22px;
  }

  .int-batch-txt-copy {
    font-size: 22px;
  }

  .dates-changing-ctas {
    display: block;
    line-height: 100px;
  }
  .reserve-seat-postpaid-button {
    padding: 15px 90px;
  }
  .tooltip-content {
    visibility: hidden;
    width: 300px;
    color: #000;
    text-align: left;
    position: absolute;
    bottom: 100%;
    left: 70%;
    transform: translateX(-50%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 999;
    background-color: #f9f0f5;
    border-radius: 12px;
    padding: 20px 22px;
  }

  .pricing-prepaid-tab-content {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    background-color: #fff;
    border: 1px solid #f9f0f5;
    border-radius: 16px;
    flex-direction: column;
    margin-top: 24px;
    padding: 24px 16px;
    font-family: "Inter", ;
    font-weight: 500;
    display: flex;
  }

  .pricing-pre-paid-title {

    text-align: center;
    font-size: 18px;
  }

  .pricing-pre-paid-title {
    font-size: 18px;
  }

  .prepaid-features-container {
    align-items: center;
    width: 100%;
  }

  .prepaid-txt3 {
    color: #475569;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }

  .w-tab-link {
    padding: 10px 20px;
    text-align: center;
  }

  /* Full Stack Developer Course in Hyderabad */
  .job-opp-info-copy {
    line-height: 28px;
    padding: 10px;
  }

  /* Full Stack Developer Course Learner Reviews */
  .learner-review-main-container {
    /* flex-flow: column; */
    justify-content: center;
    align-items: center;
    padding-top: 0px !important;
    padding-bottom: 10px !important;
    /* display: flex; */
  }

  .learner-heading {
    color: #183b56;
    margin-bottom: 28px;
    font-size: 24px;
    line-height: 34px;
  }

  .text-block-941288239896 {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 24px;
  }

  w-slider-mask {
    z-index: 1;
    white-space: nowrap;
    height: 100%;
    display: block;
    position: relative;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  .w-inline-block {
    max-width: 100%;
    display: inline-block;
  }

  /* Frequently Asked Questions */
  .faq-w-tabs {
    position: relative;
    display: block;
  }

  .image-812882290 {
    width: 293px;
    height: 32px;
  }

  .faq-q-icon-container-y {
    grid-column-gap: 5px;
    grid-row-gap: 2px;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;
    display: flex;
  }

  .faqs-all-tabs-y {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 8px 12px;
    margin-bottom: 32px;
    width: 45%;
    margin-left: 25%;
  }

  .faq-q-h-y {
    font-size: 16px;
  }

  .faq-tab-link-y.w--current {
    border: 1px solid #c76a97;
  }
  .faq-main-container-y {
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
  }
  .faq-tab-link-y.w--current {
    color: #c76a97;
    background-color: #0000;
    border-left: 1px solid #c76a97;
  }

  .faq-tab-link-y {
    color: #5a7184;
    border: 1px solid #5a7184;
    border-radius: 16px;
    flex: none;
    padding: 4px 14px;
  }

  .w-tab-link {
    vertical-align: top;
    text-align: left;
    cursor: pointer;
    color: #222;
    background-color: #ddd;
    padding: 9px 9px;
    text-decoration: none;
    display: inline-block;
    position: relative;
  }

  .w-inline-block {
    max-width: 100%;
    display: inline-block;
  }

  .faq-main-heading-y {
    letter-spacing: .2px;
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 28px;
    line-height: 40px;
  }

  /* Full Stack Developer Course Learner Reviews */
  .next-arrow-reviews {
    position: absolute;
    right: -330px;
    top: 15px;
    z-index: 1;
  }

  .prev-arrow-reviews {
    position: absolute;
    left: 280px;
    top: 360px !important;
    z-index: 1;
  }


  .arrow-icon-reviews {
    font-size: 30px;
    font-weight: 600;
  }

  /* Section--2 */
  .slider-awarded {
    height: 530px !important;
  }

  .arrow-left {
    position: absolute;
    left: 26% !important;
    top: 60px !important;
  }

  .arrow-right {
    position: absolute;
    right: 26% !important;
    top: 60px !important;
  }

  .slick-dots {
    position: absolute;
    bottom: -74px !important;
    display: block;
    width: 100%;
  }

  .card-1-founder {
    background-color: #1e3a8a;
    border: 1px solid #f9f0f5;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    margin: 20px auto;
    height: 540px;
    padding: 30px;
    box-sizing: border-box;
  }

  .text-block-9412882473 {
    width: 100%;
  }

  .date-changes-container {
    margin-top: 21px;
  }
}



@media (min-width: 768px) and (max-width: 1024px) {

  /* The TechU Advantage */
  .comparison-odd-rows {
    padding-left: 15px;
    width: 220px;
  }

  .comparison-even-rows {
    padding-left: 15px;
    width: 220px;
  }

  .comparison-txt1 {
    padding: 14px;
  }

  /* Full Stack Developer Course Fee */
  .pricing-postpaid-tab-content {
    margin-top: 32px;
    padding: 6px;
  }

  .whats-included-features-div {
    grid-column-gap: 8px;
    grid-row-gap: 2px;
    color: #475569;
    justify-content: flex-start;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 24px;
    font-family: "Inter", ;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
  }

  .text-block-941248789-copy {
    font-size: 16px;
  }

  .image-812882270 {
    flex: none;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 30px;
    right: -15px;
  }
  .tooltip-content {
    width: 260px;
    color: #000;
    text-align: left;
    position: absolute;
    bottom: 100%;
    left: 50%;
  }
  .div-block-1831980521391-copy {
    display: block;
  }

  .div-block-1831980521441 {
    padding: 20px 1px;
  }

  .new-scholar-ship-pricing-container-jfs {
    margin-left: 142px;
    margin-bottom: 20px;
  }

  .pricing-prepaid-tab-content {
    grid-column-gap: 4px;
    align-items: center;
    margin-top: 32px;
    padding: 16px;
    display: flex;
  }

  .prepaid-features-container {
    width: 58%;
  }

  .pricing-pre-paid-title {
    font-size: 22px;
  }

  .prepaid-txt2 {
    font-size: 36px;
  }

  .prepaid-txt1 {
    font-size: 22px;
  }

  /* Full Stack Developer Course in Hyderabad */
  .main-container-div {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .job-opp-info-copy {
    line-height: 31px;
    padding: 22px;
  }

  .h2a {
    font-size: 36px;
  }

  /* Frequently Asked Questions */
  .w-tab-link {
    padding: 9px 10px;
  }

  .faq-main-heading-y {
    font-size: 40px;
  }

  /* Full Stack Developer Course Learner Reviews */
  .learner-heading {
    font-size: 35px;
  }

  .next-arrow-reviews {
    position: absolute;
    right: -670px;
    top: 5px;
    z-index: 1;
  }

  .prev-arrow-reviews {
    position: absolute;
    left: 620px;
    top: 310px !important;
    z-index: 1;
  }

  .arrow-icon-reviews {
    font-size: 30px;
    font-weight: 600;
  }

  .text-block-941288239896 {
    font-size: 22px;
  }

  /* Section--2 */
  .slider-awarded {
    height: 490px;
  }

  .slick-slide {
    padding: 0 10px;
  }

  .slick-dots {
    position: absolute;
    bottom: -294px !important;
    display: block;
    width: 100%;
  }

  .arrow-left {
    position: absolute;
    left: 36%;
    top: 280px;
  }

  .arrow-right {
    position: absolute;
    right: 36%;
    top: 280px;
  }

  .date-changes-container {
    margin-top: 240px !important;
  }

  .award-image {
    width: 100%;
    height: 100%;
  }

  .nasscom,
  .nsdc-india,
  .startup-india {
    width: 180px;
    height: 40px;
  }

  .ministry-india {
    width: 100%;
    height: 40px;
  }

  .card-1-founder {
    background-color: #1e3a8a;
    border: 1px solid #f9f0f5;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    margin: 20px auto;
    height: 707px;
    padding: 30px;
  }

  .text-block-9412882473 {
    width: 100%;
  }

}