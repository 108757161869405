/* navbar section */
.nav-items-header {
  color: #334155 !important;
  text-align: center;
  padding: 0;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.nav-items-header:hover {
  color: #c76a97 !important;
}

.w-nav-link {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-decoration: none;
}

.navbar-container {
  max-width: 1220px;
}

/* hero section container */

.hero-section-container {
  flex-direction: column;
  align-items: center;
  display: flex;
  background-color: #ffffff;
  overflow: hidden;
}

.hero-main-container {
  width: 100%;
  max-width: 1200px;
  margin-top: 16px;
  text-decoration: none;
  display: block;
  padding-top: 20px;
}

.hero-links-container.other-heading-links-width {
  width: auto;
}

.hero-section-links-container {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  display: flex;
}

.hero-section-link {
  color: #334155;
  margin-bottom: 0;
  font-family: "Inter";
  font-weight: 500;
  text-decoration: none;
}

.hero-link-break-line {
  color: #94a3b8;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  transform: skew(-8deg);
}

.tectu-hero-color {
  color: #e11d48;
}

.hero-section-sub-container-2 {
  align-items: stretch;
  display: flex;
}

.hero-section-left-container {
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 48px;
  display: flex;
}

.hero-section-heading-div {
  width: 469px;
}

.hero-section-h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Satoshi Variable, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 64px;
}

.hero-section-h1.width {
  width: 540px;
}

.hero-section-h1-span-2 {
  color: #c76a97;
  font-family: Satoshi Variable, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 64px;
  position: relative;
}

.reviews-and-ratings {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  margin-top: 16px;
  display: flex;
}

.ratings-stars {
  width: 138px;
  height: 18px;
}

.rating-mobileview-1 {
  display: none;
}

.rating-points {
  color: #c76a97;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.reviews-and-ratings-c {
  color: #334155;
  width: 536px;
  margin-top: 16px;
  margin-bottom: 24px;
  font-family: "Inter";
  font-size: 16px;
  line-height: 24px;
}
.course-overview-container{
  background-color: #f9f0f5;
}
.curriculum-div-block {
  cursor: pointer;
  background-color: #f5f3ff;
  border: 1px solid #c76a97;
  border-radius: 12px;
  flex-direction: row;
  align-items: center;
  /* width: 223px; */
  margin-top: 24px;
  padding: 12px 24px;
  display: flex;
}

.curriculum-text-block {
  color: #c76a97;
  margin-right: 8px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
}

.hero-section-right-container {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  background-image: url("https://cdn.prod.website-files.com/5fa61cbbf0d432b3230f62b1/64311479cbc3b72aa24695fb_form-bg-desktop.svg");

  background-position: 49% 49%;
  background-size: cover;
  background-attachment: scroll;
  flex-direction: column;
  justify-content: center;
  align-self: flex-end;
  align-items: center;
  width: 664px;
  padding-top: 24px;
  padding-bottom: 40px;
  display: flex;
  position: relative;
  left: 72px;
}

.form-container {
  background-color: #fff;
  border: 1px solid #cbd5e1;
  border-radius: 16px;
  flex-direction: column;
  width: 500px;
  /* height: 575px; */
  padding: 15px 30px 20px;
  display: flex;
  overflow: auto;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.08);
}

.form-container-sub-1 {
  flex: none;
  justify-content: space-between;
  align-items: center;

  display: flex;
}
.fromstudent-img {
  width: 100px;
}
.text-under-line {
  color: #1e293b;
  font-family: "Inter";
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  display: inline-block;
  position: relative;
}

.form-under-img {
  position: absolute;
  top: 47px;
  left: 40px;
}

.form-student-img {
  flex: none;
  width: 100px;
  height: 65px;
}

.form-label {
  color: #334155;
  font-weight: 600;
  font-family: "Inter";
  font-size: 13px;
}

.mobile-input-container {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  position: relative;
}

select {
  border: none;
  background: none;
  padding: 0 5px;
  font-size: 14px;
  outline: none;
}

.mobile-input {
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 14px;
  padding: 0 5px;
}

input[type="text"]::placeholder {
  color: #888;
}

.mobilenumber-1 {
  position: absolute;
  left: 49px;
  color: #888;
}

.form-check-label-c {
  display: flex;
  align-items: center;
  -webkit-box-pack: center;
  gap: 10px;
}

.form-check-label {
  color: #334155;
  margin: 0px;

  font-family: "Inter";
  font-size: 13px;
  line-height: 24px;
}

.text-center-content-tfd {
  width: 100%;
  display: block;
}

.form-techu-hh {
  font-size: 0.875rem;
  color: #334155;

  font-family: "Inter";

  line-height: 24px;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
}

.form-spam-hres {
  font-size: 14px;
  line-height: 1.25rem;
  color: rgba(51, 65, 85);
  font-weight: 500;
  font-family: "Inter";
}

.form-button-demo-t {
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -webkit-box-pack: end;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
}

.button-demosection {
  width: 100%;
  background-color: #461a62;
  padding: 12px 32px;
  border-radius: 12px;

  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter ";
  text-align: center;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-links {
  text-decoration: none;
  color: #c76a97;
}

.book-demo-button-text {
  font-size: 13px;
  line-height: 1.25rem;
  font-weight: 600;

  font-family: "Inter";
}

.selectDropWrapper {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
}

.cursor {
  cursor: pointer;
}

.form-check-1 {
  display: flex;
  gap: 13px;
  /* justify-content: center; */
  align-items: center;
}

.position-relative {
  position: relative;
  width: 100%;
}

.selectedItem-name,
.form-control {
  font-family: "Inter";
  color: #666;
  font-size: 15px;
}

.whatsappdiv-container-mobile-view {
  display: none;
}

.selisted-li-list-in {
  font-family: "Inter";
  color: #666;
}

.selisted-li-list {
  padding: 5px 10px;
  font-family: "Inter";
  color: #666;
  font-size: 15px;
  margin: 10px;
}

.phone-iocn {
  display: none;
  color: black;
}

/* feactured container */

.featured-container {
  background-color: #0f172a;
}

.featured-main-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 48px;
  padding-bottom: 48px;
  display: block;
}

.program-features-grid {
  grid-column-gap: 13px;
  grid-row-gap: 13px;
  grid-template-rows: auto;
  grid-template-columns: 272px 272px 272px 284px;
  justify-content: space-between;
  display: flex;
}

.featured-div-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
}

.program-features {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  align-items: flex-start;
  display: flex;
}

.featured-image-img {
  width: 65px;
}

.featured-image {
  width: 64px;
  height: 64px;
}

.featured-course-text {
  color: #94a3b8;
  text-transform: uppercase;
  margin-bottom: 4px;
  font-family: "Inter";
  font-weight: 600;
}

.featured-course-description {
  color: #f1f5f9;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.featured-text-span {
  white-space: nowrap;
}

.text-span-1,
.text-span-2 {
  white-space: nowrap;
}

/* whatup section */
.whatsappdiv-container {
  z-index: 6;
  background-color: #fff;
  background-image: linear-gradient(#23b33a, #23b33a, #23b33a);
  border-radius: 48px;
  justify-content: center;
  align-items: center;
  height: 64px;
  margin-bottom: 0;
  margin-right: 48px;
  display: flex;
  position: fixed;
  top: auto;
  bottom: 5%;
  left: auto;
  right: 0%;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.08);
}

.whatsappdiv-block.whatup-home.whatsapp-us {
  height: 64px;
}

.whatsappdiv-block.whatup-home {
  padding: 24px 16px 24px 24px;
}

.whatsappdiv-block {
  justify-content: center;
  align-items: center;
  height: 64px;
  padding: 24px 16px 24px 24px;
  text-decoration: none;
  display: flex;
}

.whatup-text {
  color: #fff;
  margin-right: 10px;
  font-family: "Inter";
  font-weight: 400;
  line-height: 16.94px;
}

.whatup-img {
  width: 48px;
  height: 48px;
}

/* reviews section */
.reviews-slider {
  background-color: #f9f0f5;
  padding-top: 96px;
  padding-bottom: 96px;
}

.reviews-slider-container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.reviews-heading-div {
  position: relative;
  margin-bottom: 30px;
}

.reviews-heading {
  color: #0f172a;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Satoshi Variable, sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
}

.reviews-heading-uderline {
  width: 221px;
  height: 6px;
  position: absolute;
  top: auto;
  bottom: 19%;
  left: auto;
  right: 26%;
}

.reviews-slider-t {
  background-color: rgba(221, 221, 221, 0);
  width: 100%;
  /* height: 606px; */
  margin-top: 42px;
  display: flex;
  overflow: hidden;
}

.reviews-slider-card-container {
  width: 384px;
}

.reviews-map-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.reviews-slider-1 {
  vertical-align: top;
  white-space: normal;
  text-align: left;
}

.reviews-slider-caard {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  background-color: #fff;
  border: 1px solid #cbd5e1;
  border-radius: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 556.906px;
  padding: 24px;
  text-decoration: none;
  display: flex;
}

.slider-card-div-heading {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;
  display: flex;
}

.slider-card-non-enginer {
  background-color: #f0f9ff;
  border: 1px solid #0284c7;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 9px 19px;
  display: flex;
}

.slider-card-non-enginer-text {
  color: #0284c7;
  text-align: center;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
}

.slider-reviews-company-img {
  width: 132px;
}

.reviews-slider-card-content {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  width: 100%;
  display: flex;
}
.reviews-images-img {
  width: 100%;
}
.reviews-slider-text-para {
  color: #475569;
  letter-spacing: -1.1px;
  font-family: "Inter";
  font-size: 18px;

  font-weight: 500;
  line-height: 30px;
}

.reviews-slider-img-container {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  border-top: 1px solid #cbd5e1;
  justify-content: flex-start;
  align-items: center;
  padding-top: 16px;
  display: flex;
}

.reviews-slider-img {
  border-radius: 48px;
  width: 60px;
  height: 60px;
  overflow: hidden;
}

.reviews-slider-img-content {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  flex-direction: column;
  display: flex;
}

.reviews-slider-card-student-name {
  color: #334155;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.reviews-slider-card-designation {
  color: #1e293b;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.reviews-slider-card-pakage {
  color: #de1978;
  font-family: "Inter";
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
}

.reviews-slider-card-native-place {
  color: #94a3b8;
  height: 24px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.swiper-pagination {
  position: static !important;
  margin-top: 20px;
}

.slider-card-non-it-text {
  color: #059669;
  text-align: center;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
}

.slider-card-it-enginer {
  background-color: #ecfdf5;
  border: 1px solid #059669;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 9px 19px;
  display: flex;
}

.slider-card-mnc-container {
  background-color: #fffbeb;
  border: 1px solid #d97706;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 9px 19px;
  display: flex;
}

.slider-card-mnc-text {
  color: #d97706;
  text-align: center;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
}

.slider-card-carrer {
  background-color: #fff1f2;
  border: 1px solid #e11d48;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 9px 19px;
  display: flex;
}

.slider-card-carrer-gap-text {
  color: #e11d48;
  text-align: center;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
}

.book-demo-container {
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
}

.book-demo-reviews-buttons.batch-dates-buttons {
  align-items: center;
}

.book-demo-reviews-buttons {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-flow: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.batch-start-container {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  display: flex;
}

.start-batch-icon {
  background-color: #c76a97;
  border: 7.2px solid #dbeafe;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.batch-start-text {
  color: #475569;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.buttons-container {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  display: flex;
}

.book-start-a-demo {
  color: #fff;
  text-align: center;
  background-color: #461a62;
  border-radius: 8px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  font-family: "Inter";
  font-weight: 500;
  text-decoration: none;
}

.view-all-reviews {
  color: #c76a97;
  text-align: center;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 8px;
  padding: 12px 32px;
  font-family: "Inter";
  font-weight: 500;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* course overview */

.course-overview-main-container {
  flex-flow: column;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 96px;
  padding-bottom: 96px;
  display: flex;
}

.course-overview-div-block {
  position: relative;
}

.course-overview-heading {
  color: #0f172a;
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Satoshi Variable", sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
}
.trainer-head{
  color: #c76a97;
}
.trainer-strong-para{
  color: #c76a97;
}
.course-overview-underline.fsd-hyd {
  right: 5%;
}

.course-overview-underline {
  width: 211px;
  height: 12px;
  position: absolute;
  top: auto;
  bottom: 7%;
  left: auto;
  right: 18%;
}

.course-overview-text-block {
  color: #64748b;
  margin-top: 24px;
  margin-bottom: 48px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.course-overview-key-heighlights {
  flex-flow: column;
  display: flex;
}

.key-heighlights-text-block {
  font-family: "Satoshi Variable", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
}

.key-heighlights-div-block-1 {
  grid-column-gap: 86px;
  grid-row-gap: 45px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  height: 100%;
  margin-top: 24px;
  display: grid;
  overflow: hidden;
}

.key-heighlights-div-block-2 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.key-heighlights-image {
  width: 80px;
}

.div-block-content-main {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-flow: column;
  display: flex;
}

.text-block-text {
  color: #183b56;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.text-block-text-1 {
  color: #5a7184;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.key-heighlights-div-block-2.marign-copy,
.key-heighlights-div-block-2.marign {
  margin-top: 48px;
}

.key-points-drop-down-container {
  grid-column-gap: 86px;
  grid-row-gap: 45px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  overflow: hidden;
}

.course-show-hide-container {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  cursor: pointer;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 200px;
  margin-top: 48px;
  display: flex;
}

.show-allcourse-updated {
  color: #c76a97;
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.hide-allcourse-update-text {
  color: #c76a97;
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  display: none;
}

.show-more-icon {
  transition: transform 0.3s ease;
}

.transform-example.active .show-more-icon {
  transform: rotate(180deg);
}
/* trainer css */

.about-trainer-main {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 36px;
  padding-bottom: 40px;
}

.about-trainer-section {
  display: flex;
  gap: 40px;
  justify-content: center;

}

.trainer-head {
  font-size: 48px;
  font-weight: 700;
}

.trainer-para {
  font-size: 18px;
}

.trainer-btn {
  margin-left: 40px;
}

.trainer-section-left {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.trainer-img {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.trainer-ul-tags {
  line-height: 35px;
  font-size: 16px;
}

.trainer-section-left {
  width: 50%;
}

.trainer-section-right {
  width: 50%;
}
 

/* curriculam sections css */

.curriculum-main-container {
  background-color: #f9f0f5;
}

.curriculum-container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 96px;
  padding-bottom: 96px;
}

.curriculum-heading {
  color: #334155;
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Satoshi Variable", sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
}

.fullstack-curriculum-content {
  color: #64748b;
  margin-top: 16px;
  margin-bottom: 48px;
  font-family: "Inter";
  font-size: 16px;
  line-height: 28px;
}

.curriculum-tabs {
  position: relative;
}

.curriculum-tabs-menu {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  background-color: #e2e8f0;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  padding: 8px 4px;
  display: inline-block;
}

.t-tab-menu {
  position: relative;
}

.curriculum-tab-1.t--current {
  color: #334155;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 1px 0 2px rgba(0, 0, 0, 0.06);
}

.curriculum-tab-2.t--current {
  color: #334155;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 1px 0 2px rgba(0, 0, 0, 0.06);
}

.curriculum-tab-1 {
  color: #64748b;
  text-align: center;
  background-color: rgba(221, 221, 221, 0);
  padding: 8px 28px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border: none;
}

.curriculum-tab-2 {
  color: #64748b;
  text-align: center;
  background-color: rgba(255, 255, 255, 0);
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border: none;
}

.t-tab-link {
  vertical-align: top;
  text-align: left;
  cursor: pointer;
  color: #222;

  padding: 9px 30px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.curriculum-tabs-content-2 {
  margin-top: 32px;
}

.t-tab-content {
  display: block;
  position: relative;
  overflow: hidden;
}

.t--tab-active {
  display: block;
}

.t-tab-pane {
  /* display: none; */
  position: relative;
}

.ciurriculum-main {
  border-bottom: 1px solid #94a3b8;
}

.curriculum-main-content {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  display: flex;
}

.curriculum-fundamentals-main-headings {
  color: #334155;
  letter-spacing: 0.2px;
  cursor: pointer;
  flex: none;
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.text-content-heading {
  color: #334155;
  white-space: nowrap;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.curriculum-child {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  width: 100%;
  padding-bottom: 24px;
  display: flex;
}

.fundamentals-main-paragraphs,
.fundamentals-project-heading {
  color: #334155;
  margin-bottom: 0;
  font-family: "Inter";
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
}

.inner-cards {
  background-color: #fff;
  border: 1px solid #94a3b8;
  border-radius: 20px;
  flex-direction: column;
  width: 100%;
  display: flex;
}

.fundamentals-submain-container {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  display: flex;
}

.accordion-fundamentals-children {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  margin-bottom: 16px;
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
}

.body-rounded-unorder-list {
  list-style-type: disc;
}

.body-rounded-unorder-list {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  color: #334155;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 0;
  padding-left: 28px;
  font-family: "Inter";
  font-size: 16px;
  line-height: 24px;
  list-style-type: disc;
  display: flex;
}

.body-square-unorder-list {
  list-style-type: square;
}

.body-square-unorder-list {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  color: #334155;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 0;
  padding-left: 48px;
  font-family: "Inter";
  font-size: 16px;
  line-height: 24px;
  list-style-type: disc;
  display: flex;
}

.body-fundamentals-children {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  flex-direction: row;

  display: flex;
}

.fundamentals-submain-container {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  display: flex;
}

.accordion-fundamentals-children-1 {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  flex-direction: row;
  width: 100%;
  display: flex;
}

.accordion-fundamentals-children-2 {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  flex-direction: row;
  display: flex;
}

.body-w-tyr {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  flex-direction: column;
  width: 100%;
  display: flex;
}

.demo-button-collection-container {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
  display: flex;
}

.demo-button-div-block {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  padding-bottom: 32px;
  display: flex;
}

.accordion-button:not(.collapsed) {
  background-color: #fff !important;
  box-shadow: none !important;
}

.accordion-button::after {
  width: 30px !important;
  clip-path: path(
    "M7.93934 10.9393C8.52513 10.3536 9.47487 10.3536 10.0607 10.9393L15 15.8787L19.9393 10.9393C20.5251 10.3536 21.4749 10.3536 22.0607 10.9393C22.6464 11.5251 22.6464 12.4749 22.0607 13.0607L16.0607 19.0607C15.4749 19.6464 14.5251 19.6464 13.9393 19.0607L7.93934 13.0607C7.35355 12.4749 7.35355 11.5251 7.93934 10.9393Z"
  );
  background-color: #475569;

  height: 30px;
}

.accordion-item {
  border: 1px solid #94a3b8;
  box-shadow: none;

  border: 1px solid #94a3b8;
  border-radius: 20px !important;
  flex-direction: column;
  width: 100%;
  display: flex;
}

.accordion-flush > .accordion-item {
  border: 1px solid #94a3b8 !important;
  border-radius: 20px !important;
}

.accordion-button {
  border-radius: 20px !important;
}

.form-below-content-text {
  color: #334155;
  margin-right: 12px;
  font-family: "Inter";
  font-weight: 500;
}

.whatup-text {
  display: flex;
}

.accordion {
  --bs-accordion-btn-focus-box-shadow: #fff !important;
}

.navbar-brand-1 {
  display: none;
}

.error {
  color: red;
  font-family: "Inter";
  font-size: 12px;
}
.rationg-pakage-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.highest-pakage-img {
  width: 329px;
  height: 220px;
}

@media (max-width: 767px) {
  /* navbar  */
  .navbar-nav {
    background-color: #0f172a;
    text-align: left;
  }

  .navbar-brand-1 {
    display: flex;
    margin: 20px;
    justify-content: space-between;
  }

  .navbar-toggle-mobile {
    border: 1px solid #fff;
  }

  .navbar-mobile-button {
    color: white !important;
  }

  .w-nav-link {
    color: #fff !important;
    margin-left: 10px;
  }

  .navbar-nav {
    text-align: left;
    background-color: #183b56;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    position: fixed;
    top: 0;
    bottom: auto;
    left: 0%;
    right: 0%;
  }
  
  /* trainer */
  .about-trainer-section {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-top: 25px;
  }

  .trainer-section-right {
    width: 100%;
  }

  .trainer-img {
    width: 100%;
    height: auto;
  }

  .trainer-section-left {
    width: 100%;
    margin-left: 0px;
  }

  .trainer-head {
    font-size: 40px;
    margin-bottom: 18px;
  }
  .trainer-btn {
    margin-left: 0px;
  
}
  .trainer-btn-div {
    display: flex;
    justify-content: center;
    
    margin-left: 85px;
    width: 55% !important;
  }
 
  /* hero section */
  .hero-section-sub-container-2 {
    display: flex;
    flex-direction: column;
  }

  .hero-section-heading-div {
    width: auto;
  }

  .hero-section-h1.width {
    width: auto;
  }

  .hero-section-h1 {
    font-size: 34px;
    line-height: 48px;
  }

  .hero-section-h1-span-2 {
    text-align: left;
    font-size: 34px;
    font-weight: 700;
    line-height: 48px;
  }

  .hero-main-container {
    padding: 20px;
  }

  .rating-mobileview {
    display: flex;
  }
  .rationg-pakage-container {
    display: block;
  }
  .highest-pakage-img {
    margin-top: 15px;
    width: 100%;
    height: 100%;
  }

  .rating-mobileview-1 {
    display: none;
  }

  .reviews-and-ratings-c {
    order: 0;
    width: auto;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 22px;
  }

  .curriculum-div-block {
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 10px 16px;
    display: flex;
  }

  .hero-section-right-container {
    z-index: 0;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-image: none;
    background-size: auto;
    width: 100%;
    height: auto;
    padding-top: 76px;
    padding-bottom: 0;
    position: relative;
    left: auto;
  }

  .form-container {
    z-index: 1;
    border-style: none;
    order: 2;
    width: 100%;
    height: auto;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    position: relative;
  }

  .form-below-content {
    position: absolute;
    top: 0%;
  }

  .whatsappdiv-container-mobile {
    width: 100%;
    position: fixed;
  }

  .whatsappdiv-container {
    /* background-color: #fff; */
    display: none;

    border-radius: 0px;
    background-image: linear-gradient(#fff, #fff 0%, #fff);
  }

  .whatsappdiv-block-mobile-view.whatup-home {
    padding: 19px 0px 0px 0px;
  }

  .whatsappdiv-container-mobile-view {
    background-image: linear-gradient(#fff, #fff 0%, #fff);
    display: flex;
  }

  .whatsappdiv-block-mobile-view {
    justify-content: space-around;
    align-items: center;
    height: 64px;
    padding: 24px 16px 24px 24px;
    text-decoration: none;
    display: flex;
    width: 100%;
  }

  .form-below-content-text {
    color: #334155;
    margin-right: 12px;
    font-family: "Inter";
    font-weight: 500;
  }

  .featured-main-container {
    margin-left: 0;
    margin-right: 0;
    padding: 48px 16px;
    display: block;
  }

  .program-features-grid {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    flex-flow: column;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr;
    display: flex;
  }

  .course-overview-main-container {
    padding: 48px 16px;
  }

  .course-overview-div-block {
    width: 328px;
  }

  .course-overview-heading {
    font-size: 24px;
    line-height: 34px;
  }

  .course-overview-underline.fsd-hyd {
    right: 25%;
  }

  .course-overview-underline {
    width: 110px;
    height: 0;
    bottom: 18%;
  }

  .key-heighlights-text-block {
    font-size: 20px;
    line-height: 36px;
  }

  .key-heighlights-div-block-1 {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-template-columns: 1fr;
  }

  .key-heighlights-div-block-2 {
    justify-content: flex-start;
    align-items: center;
  }

  .key-heighlights-image {
    width: 48px;
    height: 48px;
  }

  /* reviews section */
  .reviews-slider {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .reviews-slider-container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .reviews-heading-div {
    width: 328px;
  }

  .reviews-heading {
    text-align: left;
    font-size: 24px;
    line-height: 34px;
  }

  .reviews-heading-uderline {
    width: 110px;
    height: 0;
    right: 48%;
  }

  .slider-423 {
    width: 328px;
    height: 556px;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-main {
    margin: 25px;
  }

  .reviews-slider-caard {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    justify-content: flex-start;
    height: 100%;
    /* min-height: 396px;
    max-height: 492px; */
    padding: 16px 12px;
  }

  .slider-card-div-heading {
    align-items: center;
  }

  .slider-reviews-company-img {
    width: 102px;
  }

  .reviews-slider-text-para {
    letter-spacing: 0;
    justify-content: flex-start;
    align-items: flex-start;
    /* height: 288px; */
    font-size: 16px;
    line-height: 24px;
    display: block;
  }

  .reviews-slider-img-container {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    justify-content: flex-start;
    align-items: center;
  }

  .reviews-slider-img {
    flex: none;
    width: 48px;
    height: 48px;
  }

  .reviews-slider-card-container {
    width: 100%;
  }

  .book-demo-container {
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    padding-left: 16px;
    padding-right: 16px;
    display: block;
  }

  .book-demo-reviews-buttons {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-flow: column;
  }

  .batch-start-container {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .phone-iocn {
    display: flex !important;
    color: #fff;
  }

  .phone-icon-div {
    width: 50%;
    display: flex;
    justify-content: center;
    background-color: #c76a97;
    padding: 3px;
  }

  .whapup-div {
    width: 50%;
    display: flex;
    justify-content: center;
    background-color: #059669;
  }

  .whatup-text {
    display: none;
  }

  .buttons-container {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-flow: column;
    width: 100%;
  }

  .view-all-reviews,
  .book-start-a-demo {
    width: 100%;
  }

  /* curricular section */
  .curriculum-container {
    padding: 48px 16px;
  }

  .curriculum-heading {
    color: #1e293b;
    text-align: left;
    letter-spacing: -0.8px;
    width: auto;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
  }

  .fullstack-curriculum-content {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 22px;
  }

  .curriculum-tabs-menu {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    justify-content: space-between;
    width: 326px;
    height: 56px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
  }

  .curriculum-tab-1 {
    padding-left: 4px;
    padding-right: 4px;
    font-size: 14px;
  }

  .curriculum-tab-1.t--current {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 14px;
  }

  .curriculum-tab-2 {
    padding: 8px 4px;
    font-size: 14px;
  }

  .curriculum-tab-2.t--current {
    padding-left: 8px;
    padding-right: 8px;
  }

  .curriculum-tabs-content-2 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .curriculum-main-content {
    grid-column-gap: 0px;
    grid-row-gap: 2px;
  }

  .curriculum-fundamentals-main-headings {
    flex: 1;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    display: block;
  }

  .accordion-flush > .accordion-item {
    border: 1px solid #94a3b8 !important;
    border-radius: 20px !important;
    box-shadow: none !important;
  }

  .accordion-button:not(.collapsed) {
    background-color: #fff !important;
    box-shadow: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* navbar  */
  .navbar-brand-1 {
    display: flex;
    margin: 20px;
  }

  .navbar-brand-1 {
    display: flex;
    margin: 20px;
    justify-content: space-between;
  }

  .navbar-toggle-mobile {
    border: 1px solid #fff;
  }

  .navbar-mobile-button {
    color: white !important;
  }

  .navbar-nav {
    background-color: #183b56;
    position: fixed;
    top: 0;
    bottom: auto;
    left: 0;
    right: 0;
    transform: translateY(0px);
    transition: transform 400ms ease 0s;
  }

  .navbar-nav.hidden {
    transform: translateY(-100%);
  }

  .nav-items-header {
    color: #fff !important;
    margin-left: 10px;
  }

  /* hero section */
  .hero-section-sub-container-2 {
    display: flex;
    flex-direction: column;
  }

  .hero-main-container {
    padding: 0px 25px;
  }

  .featured-main-container,
  .course-overview-main-container,
  .curriculum-container {
    padding: 25px;
  }

  .hero-section-h1.width {
    width: 600px;
  }

  .reviews-and-ratings-c {
    width: 100%;
  }

  .featured-div-grid {
    display: grid;
  }

  .hero-section-right-container {
    background-image: none;
    background-size: auto;
    width: 100%;
    height: auto;
    padding-top: 0px;

    position: static;
    left: auto;
  }
  .highest-pakage-img {
    width: 100%;
    height: 100%;
  }

  .course-overview-underline.fsd-hyd {
    right: 35%;
  }

  .key-heighlights-div-block-1 {
    grid-column-gap: 10px;
    grid-row-gap: 30px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    height: 100%;
    margin-top: 24px;
    display: grid;
    overflow: hidden;
  }

  .key-heighlights-image {
    width: 92px;
    height: 50px;
  }

  .reviews-slider-text-para {
    height: 275px;
    overflow: hidden;
  }

  /* review section */
  .reviews-slider {
    padding: 25px 30px;
  }

  .reviews-heading-uderline {
    width: 212px;
    height: 6px;
    position: absolute;
    top: auto;
    bottom: 11%;
    left: auto;
    right: 52%;
  }

  .slider-card-non-enginer-tex {
    font-size: 10px;
  }

  /* curriculam */
  .accordion-flush > .accordion-item {
    border: 1px solid #94a3b8 !important;
    border-radius: 20px !important;
    box-shadow: none;
  }

  .accordion-button:not(.collapsed) {
    background-color: #fff !important;
    box-shadow: none !important;
  }

  .reviews-map-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
  }

  .reviews-slider-card-container {
    width: 350px;
  }

  .slider-card-div-heading {
    display: block;
  }
   /* trainer */
   .about-trainer-section {
    display: flex;
    gap: 10px;
    padding: 10px;
  }

  .trainer-section-right {
    width: 100%;
  }

  .trainer-img {
    width: 100%;
    height: auto;
    margin-top: 98px;
  }

  .trainer-section-left {
    width: 100%;
    margin-left: 0px;
  }

  .trainer-head {
    font-size: 40px;
    margin-bottom: 18px;
  }
 
}
